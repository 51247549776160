import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "What's the deal with Genshin Impact Wishes? (Part 2)",
  "details": "The Consolidated Conspiracy Continues",
  "date": "2021-06-09",
  "tags": ["Bootstrapping", "Genshin Impact", "Gaming"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this installment of the Genshin series, we'll be further investigating the Genshin Impact wishing system. In `}<a parentName="p" {...{
        "href": "/blog/genshin-wishes-p1"
      }}>{`part one`}</a>{`, we discussed the contradiction between having a flat rate of 0.6% to get a 5 star (with pity at 90) and having a consolidated rate of 1.6%. Turns out, the consolidted rate should be 1.435% instead!`}</p>
    <p>{`But what if there isn't a flat rate, and instead the 1.6% consolidated is true? The following post will give a rough guess as to what those underlying rates may be.`}</p>
    <p>{`In future posts we may improve our estimated rates with hyperparamater turning, machine learning, and optimization. However, without raw data, this may prove a redundant task, and may focus on other aspects of the so called 'soft pity' rates.`}</p>
    <p>{`Let's get into it!`}</p>
    <h2>{`Programming Details`}</h2>
    <p>{`I don't think the programming details are all that important in this section. Check out  `}<a href="https://github.com/MarcoRSousa" target="_blank">{`my GitHub`}</a>{` for more code. I simply carry out a bootstrap of the simulations with a rates array that are freely adjustable and see if we can artificially produce that 1.6% consolidated rate. Nonetheless, some of the code is below, but feel free to skip ahead.`}</p>
    <p>{`Imports`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` numpy `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` np
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` pandas `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` pd
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` random
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` collections `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Counter
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` matplotlib`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pyplot `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` plt`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`My wishing mechanisms. I make rates an input. I also only care about 5 stars for now. I lazily use a global count5 to represent how far along pity we are.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`roll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`probMassFunc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.006`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.994`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
  INPUT: probMassFunc
  OUTPUT: 0,1 (not 5 star; 5star)
  FUNCTIONALITY: Simulate 1 roll for just 5 star or not
  """`}</span>{`

  value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`choice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`replace`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`probMassFunc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`conditionalRoll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wishRates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
  INPUT:Rates; array of shape 90,
  OUTPUT: A sommon (5 star or not)
  Dependency: Requires global count5 starting at 1 and rates array
  Functionality: Now we roll with style (just keeping track)
  """`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`global`}</span>{` count5

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    summon `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    summon `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` roll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`probMassFunc`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`wishRates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`count5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`wishRates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`count5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`summon `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`summon`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`My bootstrap mechanism, which really just repeats above. Make sure to choose your own rates array.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bootstrap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bootstrapNum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` wishNum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`69000`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` wishRates `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` rates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
  INPUT: none; can adjust bootstrap and wishes and rates array
  OUTPUT: An array collecting consolidate rates of 5 stars
  """`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# How many bootstraps and how many wishes per bootstrap`}</span>{`
  consolidated5Distribution `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bootstrapNum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  wishArr `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wishNum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Each bootstrap`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` stat `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bootstrapNum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Storing results`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` wish `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wishNum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      wishArr`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`wish`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` conditionalRoll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wishRates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Creating a dict counter of the array`}</span>{`
    countDict `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Counter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wishArr`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Calculating the consolidated rate for 5 and 4 star`}</span>{`
    consolidated5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` countDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`wishNum

    consolidated5Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`stat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` consolidated5

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` consolidated5Distribution
`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`With this, calling bootstrap will carry out our simulation for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bootstrapNum`}</code>{` number of times, with each iteration having `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`wishNum`}</code>{` number of wishes. You may also define the rates as well.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`distribution `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bootstrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`69000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`inputRates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
distribution2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bootstrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#If you created an array called rates`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <h2>{`So what could the rates look like?`}</h2>
    <p>{`So here's a few rates I tried which are plotted below. Of course, I tried the flat 0.6% rates (black), and that again reproduced our 1.435%. I then tried three other methods of approximately achieving a consolidated rate of 1.6%. Simply raising the flat rate is one way to inrease the consolidated rate, which is represented by the cyan line. Another way is to mostly keep your 0.6% rates, but increase your rates at some point create a 'soft pity'. However, if you believe in a sudden soft pity at 74, for example, then the rates have to skyrocket to accomidate the consolidated rate. This is represented with the red line. A way of mending this is to make a broader curve for the rates. This is represented by the blue line.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "504px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.7659574468085%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAACaUlEQVQ4y6WT3U4TQRTHx68LH8Ib76X6Al4RQrz3UYxS9LokJPAIDd75DAIL7RZLCQ1WSpuitrIfbXe7u7Mf3bDtzvzNbEu1UATiSc6emZOZ3/zPnhmiHG6TA1l6elwuvdgvHcyVy4epYrGYkiQplclk5ldWVhbX19cXMpnMq7W1tYXV1dXFbDb78rTRePatVksdn56mjiuVuWq1+lyW5ScEEYijd7cCi0LVVKaqKlqtFjqdDgzDQBRFGA6HV5xxDnZ+DhYEYADnnIv1Hwkh5NGPliGpbQeVylF8cnKSALvdLjRN42EYcgCzHKAU0HUxYuLT6/U2CABimMZmEPjihKFlWzyOYz4YDHgURckYIwXTJnKUct5ui3EsgKZpbiQKXepKGFmMGZZsn05gorDdFnLZBCgUep63Nd4YX2wW8cKvQEVexCAA17RpICHkAaVU+ht42WcBk9DvgwuFnF+vELewySGeJyjgjE8pfOi67vZdgJOSfQqzYcP32P8rTIAeRadBQR02pfDeXRVelHxuUPhnVgLkyT0cK7wLcHQBR8Cw46KvWPAoY0LjTOCsLl/XeUcLwCwbYZ+xfgg4zqjky8BbmVju/HI5fI+Hdj9OgPafpnweVxSNX8uNHg0Ru6oXYxDFzHEH1E+akhUC79u2U2wbFC2lhzPNuuq6BUW3J3NFt9A8s9CsK+hYDvSWAk2jMM3ep+SlVKvV10eHX9P7e6U3hdzeUiH3ZWkU95bk3UI6J+WXdzZ3P+R35LTI7W7nlwu5Qrp8UHq3Xyym83L+/fd69e3PZnM++Yee5yel/8sJIY9vWlOr18lvw+oA5levhT4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Non Unique Rates",
          "title": "Non Unique Rates",
          "src": "/static/0671b84f942b900acd36afc1506486a4/08115/interesting-rates.png",
          "srcSet": ["/static/0671b84f942b900acd36afc1506486a4/4dcb9/interesting-rates.png 188w", "/static/0671b84f942b900acd36afc1506486a4/5ff7e/interesting-rates.png 375w", "/static/0671b84f942b900acd36afc1506486a4/08115/interesting-rates.png 504w"],
          "sizes": "(max-width: 504px) 100vw, 504px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`We can significantly improve our results with some tuning and a little bit of machine learning, but that's not neccesary to discuss the big takeaway from these simulations.`}</p>
    <h2>{`So what do these results mean about the rates?`}</h2>
    <p>{`The essential idea is to demonstrate that the rates cannot be theoretically determined without data because the solution is not unique. In particular, there are many solutions, and likewise many rates, that produce that 1.6% consolidated rate. `}</p>
    <p>{`In order to gain more insight on the underlying rates, we would need a sufficiently large amount of data to guide our analysis, or the rates would have to be simply disclosed. But of course, that'd ruin the fun.`}</p>
    <p>{`Until next time; and best of luck!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      